import "../Css/ServiceDetails.css";

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import Footer from "../Component/Footer/FooterPage";
import { Link, useLocation, useParams } from "react-router-dom";
import contentData from "./Content";
import { MetaTags } from "react-meta-tags";

const ServiceDetails = () => {
  const [details, setDetails] = useState(null); // Initialize details state to null
  const [eventFdata, setEventFdata] = useState(null); // Declare EventFdata at the outer scope

  const search = useLocation();
  const Path = search.pathname;

  useEffect(() => {
    let foundEventFdata = null;
    let foundServiceData = null;

    contentData.forEach((data) => {
      const foundSubservice = data.Subservice.find((subservice) => subservice.slugdata === Path);
      if (foundSubservice) {
        foundEventFdata = data;
        foundServiceData = foundSubservice;
      }
    });

    if (foundEventFdata && foundServiceData) {
      setEventFdata(foundEventFdata);
      setDetails(foundServiceData);
    } else {
      console.log("No matching data found for the provided path.");
      setDetails(null); // Reset details state to null
      // Handle this case appropriately, such as displaying an error message.
    }
  }, [Path]); // Add Path as a dependency to useEffect



  return (
    <>

      <MetaTags>
        <title>{details && details.Servicetitle}</title>
        <meta title={details && details.Servicetitle} />
        <meta name="description" content={details && details.ServiceDes} />
        <meta name="keywords" content="Interior in Andheri,Custom Interior Designers in Andheri,Interior Design Services in Andheri,Best Interior Designer in Andheri,Top Architecture in Andheri,Interior Designing With Furnishing Services Andheri,Interior Decorator in Andheri,Interior Designer near Andheri,Best Interior Designer in Andheri,Top Interior Designer in Andheri,Architecture in Andheri,Top commercial architecture designer in andheri,Interior Designing With Furnishing Services Andheri" />
        <link rel="canonical" href={details && details.ServiceCanonical} />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={details && details.Servicetitle} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={details && details.ServiceCanonical} />
        <meta
          property="og:description"
          content={details && details.ServiceDes}
        />
        <meta
          property="og:image"
          content="https://thehivemakers.com/static/media/Logo2.d18c2a41e337393532b6.png"
        />
      </MetaTags>

      <section className="ServiceDetails-Section-1">
        <div className="container">
          <div className="ServiceDetails-Section-con">
            <div className="ServiceDetails-Section-Text">
              <h1>{details && details.Serviceh1tag}</h1>
              <div className="ServiceDetails-Section-NavPage">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                  </svg>
                  Home
                </span>
                <h3>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                  </svg>
                </h3>
                <h2>{details && details.servicename}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ////////////////////////////////////////////////////////////////////////////////// */}

      <section className="service-details-section-2-con">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="service-details-con">
                <div className="Service-details-con-heading">
                  <h2>Our Services</h2>
                </div>
                <div className="service-details-section-2-service-name">
                  {
                    eventFdata && eventFdata.Subservice.map((elem) => {
                      return (
                        <>
                          <div className="service-details-item-body-con">
                            <Link
                              to={elem.slugdata}
                            >
                              <h3 className="service-taxt-style"> {elem.servicename}</h3>
                            </Link>
                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            {details && (
              <div className="col-md-8">
                <div className="section-Service-details-info-con">
                  <div className="section-Service-details-info-con-intro">
                    <h3>{details.servicename}</h3>
                    <h4>{details.header}</h4>
                    <p>{details.content}</p>
                  </div>

                  <div className="section-service-details-info-points-con">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="section-service-details-points-image-con">
                          <img src={details.verticalImage} alt={details.alt} />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="section-service-details-points-body">
                          <div className="section-service-details-points-card">
                            <h5 className="Section-Service-details-points-style">
                              {details.desc1}
                            </h5>
                            <p>{details.para1}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Section-Service-Details-Points-2-con">
                      {
                        details.Points && details.Points.map((elem) => {
                          return (
                            <>
                              <div className="section-service-details-points-card">
                                <h5 className="Section-Service-details-points-style">
                                  {elem.pointshead01}
                                </h5>
                                <p>{elem.pointshead01para01}</p>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                    <div className="section-service-details-points-body-image">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        // autoplay={{
                        //   delay: 2500,
                        //   disableOnInteraction: false,
                        // }}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                          },
                          1024: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                          },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                      >
                        {
                          details.Imagesdata.map((elem) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div className="Service-Details-Card-Image-con">
                                    <img src={elem.Images} alt={elem.alt} />
                                  </div>
                                </SwiperSlide>
                              </>
                            )
                          })
                        }
                      </Swiper>
                    </div>
                    <div className="section-service-details-points-body-para">
                      <p>{details.detail1}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="service-details-2-our-works-con">
        <div className="container">
          <div className="service-details-2-our-work">
            <div className="service-detals-2-our-work-details">
              <h2>Check Out Our Works</h2>
              <div className="service-detals-2-our-work-details-para">
                <p>
                  We work closely with clients to gain an understanding of your
                  desires both philoso phical and practical.
                </p>
              </div>
              <div className="service-detals-2-our-work-details-btn">
                <Link to="/interior-decorator-andheri">
                  <button>view portfolio</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ServiceDetails;
