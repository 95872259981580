import React from 'react';
import "../../Component/NavBar/Header.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// Logo
import Logo from "../../Images/Logo/Hive.png";
import { Link } from 'react-router-dom';
// social icons 
import { AiFillInstagram } from "react-icons/ai";
import { TiSocialFacebook } from "react-icons/ti";
import { IoIosMail } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
const Header = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <div className='Logo-Con'>
                                <img src={Logo} alt="" />
                            </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav
                            className="my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="/custom-interior-designers-andheri"><Link to="/custom-interior-designers-andheri">About Us</Link></Nav.Link>
                            <Nav.Link href="/interior-design-services-andheri"><Link to="/interior-design-services-andheri">Service</Link></Nav.Link>
                            <Nav.Link href="/interior-decorator-andheri"><Link to="/interior-decorator-andheri">Gallery</Link></Nav.Link>
                            <Nav.Link href="/interior-designers-near-andheri"><Link to="/interior-designers-near-andheri">Contact us</Link></Nav.Link>
                        </Nav>
                        <Nav>
                            <div className='social-media-links-con'>
                                <div className='social-media-links'>
                                    <a href='https://www.instagram.com/hivemakers/' target='blank'>
                                        <AiFillInstagram />
                                    </a>
                                    <a href='https://www.facebook.com/hivemakers/' target='blank'>
                                        <TiSocialFacebook />
                                    </a>
                                    <a href="mailto: ceo@hivebuilders.in" target='blank'>
                                        <IoIosMail />
                                    </a>
                                    <a href="tel:+919653468744">
                                        <IoIosCall />
                                    </a>
                                </div>

                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header