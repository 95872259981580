import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Component/NavBar/Header';
import Home from './Pages/Home';
import About from './Pages/About';
import Service from './Pages/Service';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import ServiceDetails from './Pages/ServiceDetails';
import contentData from './Pages/Content';
import ErrorPage from "./Component/Error Page/ErrorPage";
function App() {
  // console.log(contentData)
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/custom-interior-designers-andheri' element={<About />} />
          <Route path='/interior-design-services-andheri' element={<Service />} />
          <Route path='/interior-designers-near-andheri' element={<Contact />} />
          <Route path='/interior-decorator-andheri' element={<Gallery />} />
          <Route path='/*' element={<ErrorPage />} />

          {/* <Route path='/service/:slug' element={<ServiceDetails />} /> */}
          {/* Map through each item in contentData */}
          {contentData.map((route, index) => (
            // Map through each Subservice array within the current route
            route.Subservice.map((subService) => (
              // Render a Route component for each subService, with its respective slugdata as the path
              <Route
                key={subService.id} // Assuming each subService has a unique ID
                exact
                path={subService.slugdata}
                // Pass the subService data as props to the ServiceDetails component
                element={<ServiceDetails />}
              />
            ))
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
