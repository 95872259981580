import React, { useLayoutEffect } from "react";
import "../Css/Service.css";
import image01 from "../Images/Pages/Service/lounch area.webp";
import formimage01 from "../Images/Pages/Gallery/residential interior design/residentialinterior30.webp";
import { LuPlus } from "react-icons/lu";
import { Button, Form, Input } from "antd";
import Footer from "../Component/Footer/FooterPage";
import contentData from "./Content";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import $ from "jquery";
const Service = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const formSubmit = ((values) => {
    console.log(values)

    var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">Hive Interior Designer</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Email Id:</strong></td><td style="text-align:left">' + values.email + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' + values.subject + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Hive Interior Designer</p></div></body></html>';

    $.post("https://skdm.in/server/v1/send_lead_mail.php", {
      toEmail: "constructionhive@gmail.com",
      fromEmail: "skdmlead@gmail.com",
      bccMail: "skdmlead@gmail.com",
      mailSubject: "New Customer Lead",
      mailBody: body,
      accountName: "HIVE",
      accountLeadSource: "https://jutiepie.in/",
      accountLeadName: values.name,
      accountLeadEmail: values.email,
      accountLeadPhone: values.contact,
    },

      function (dataa, status) {
        console.log('data :' + dataa);
        console.log("name:" + values);

      });

    alert("Your Form has Submitted Our team will contact with You  soon.");
    values.preventDefault()

    return false;


  })

  return (
    <>
      <MetaTags>
        <title>Interior Design Services in Andheri | HIVE</title>
        <meta title="Interior Design Services in Andheri | HIVE" />
        <meta name="description" content="Discover expert Interior Design Services in Andheri to transform your space into a haven of style and functionality. Contact us today for a consultation." />
        <meta name="keywords" content="Interior in Andheri,Custom Interior Designers in Andheri,Interior Design Services in Andheri,Best Interior Designer in Andheri,Top Architecture in Andheri,Interior Designing With Furnishing Services Andheri,Interior Decorator in Andheri,Interior Designer near Andheri,Best Interior Designer in Andheri,Top Interior Designer in Andheri,Architecture in Andheri,Top commercial architecture designer in andheri,Interior Designing With Furnishing Services Andheri" />
        <link rel="canonical" href="https://thehivemakers.com/interior-design-services-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Interior Design Services in Andheri | HIVE" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thehivemakers.com/interior-design-services-andheri" />
        <meta
          property="og:description"
          content="Discover expert Interior Design Services in Andheri to transform your space into a haven of style and functionality. Contact us today for a consultation."
        />
        <meta
          property="og:image"
          content="https://thehivemakers.com/static/media/Logo2.d18c2a41e337393532b6.png"
        />
      </MetaTags>

      <section className="Service-Section-1">
        <div className="container">
          <div className="Service-Section-con">
            <div className="Service-Section-Text">
              <h1>Interior Design Services in Andheri</h1>
              <div className="Service-Section-NavPage">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                  </svg>
                  Home
                </span>
                <h3>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                  </svg>
                </h3>
                <h2>Services</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Service-Section2-con">
        <div className="container">
          <div className="Service-Section-2-about">
            <div className="row">
              <div className="col-md-5">
                <div className="Service-Section-2-Details-con">
                  <div class="Home-Architecture-Sec-heading Service-section-2-heading">
                    <h2 className="Home-Architecture-Sec-heading-text">The enduring adoration for beauty What Keeps Everyone Smitten</h2>
                  </div>
                  <p className="Home-Architecture-Sec-heading-para">
                    At Hive's Interior Design Services in Andheri, we are renowned for our unparalleled luxury and bespoke craftsmanship. With a commitment to excellence, we curate spaces that embody sophistication and opulence, tailored to your unique preferences and lifestyle. Our dedication to providing the highest quality of interior design ensures that every detail is meticulously crafted, from sumptuous fabrics to exquisite finishes.
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <div className="Service-Section-2-details-image-con">
                  <div className="Service-Section-2-details-image">
                    <img src={image01} alt="Interior Design Services in Andheri" />
                  </div>
                </div>
              </div>
            </div>
            <div className="Service-Section-2-details-below-para">
              <p>
                Embracing the timeless allure of architectural finesse, we seamlessly blend form and function to create environments that exude elegance and comfort. Our team of skilled designers and artisans, specializing in Interior Design Services in Andheri, collaborate closely with clients to bring their vision to life, ensuring every space reflects their individual taste and personality.
              </p>
              <p>
                Experience the epitome of luxury living with Hive's Interior Design Services in Andheri, where every corner is a masterpiece waiting to be discovered. Let us transform your home or workspace into a sanctuary of refined beauty and unparalleled sophistication.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="Service-Section-3-con">
        <div className="container">
          <div class="Gallery-Section-Tabs-con">
            <div class="Gallery-Section-tabs-head-con">
              <h2 className="background-text-color">Recent Projects</h2>
              <p className="background-text-color">
                Hive design intertwines various elements of place-making and environmental stewardship.
              </p>
            </div>
          </div>
          <div className="Service-Section-3-card-con">
            <div className="row">
              {
                contentData.map((elem) => {
                  return (
                    <>
                      <div className="col-md-4">
                        <div className="Service-Section-3-Card-Body">
                          <Link to={elem.slugdata}>
                            <div className="Service-Section3-Card-body-con">
                              <img src={elem.ServiceImage} alt={elem.servicealt} />
                              <div className="Service-Section-3-overlay-con">
                                <div className="Service-Section-3-Overlay-icon">
                                  <LuPlus />
                                </div>
                              </div>
                            </div>
                            <div className="Service-Section-3-Card-text-con">
                              <h2>{elem.servicename}</h2>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>

      <section className="section-Service-4-con">
        <div className="container">
          <div class="Gallery-Section-tabs-head-con">
            <h2>Have a project in mind?</h2>
            <p>
              Hive design intertwines elements of place-making and environmental stewardship, merging diverse strands into a cohesive whole.
            </p>
          </div>
          <div className="section-Service-4-form-con">
            <div className="row">
              <div className="col-md-5 p-0">
                <div className="Section-Service-4-form-Image-con">
                  <img src={formimage01} alt="Interior design solutions Andheri" />
                </div>
              </div>
              <div className="col-md-7 p-0">
                <div className="Service-Section-4-form-con-main">
                  <div className="Service-Section-4-form-con">
                    <div className="Service-Section-4-form-head">
                      <h3 className="Service-form-head">Request a Call Back</h3>
                      <p>
                        Have a question in mind? Do not hesitate to contact us.
                      </p>
                    </div>

                    <div className="Service-Section-form-inputs-con">
                      <Form layout="vertical" onFinish={formSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item
                              label="Your Name"
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Email address"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your email",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Phone Number"
                              name="contact"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your phone number",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Subject"
                              name="subject"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your message",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </div>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Service;
