import React, { useLayoutEffect, useState } from 'react';
import "../Css/Contact.css";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import { SiGooglemaps } from "react-icons/si";
import Footer from "../Component/Footer/FooterPage";
import { MetaTags } from 'react-meta-tags';
import { Form, Input, Select } from 'antd';
import $ from "jquery";

const Contact = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const submitfrom = ((values) => {
        console.log("values",values)
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">Hive Interior Designer</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Service:</strong></td><td style="text-align:left">' + values.service + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Hive Interior Designer</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "constructionhive@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "HIVE",
            accountLeadSource: "https://jutiepie.in/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        values.preventDefault()
        return false;
    })

    return (
        <>

            <MetaTags>
                <title>Interior Designer near Andheri | HIVE</title>
                <meta title="Interior Designer near Andheri | HIVE" />
                <meta name="description" content="Explore expert Interior Designers near Andheri offering innovative solutions for your home. Get inspired and transform your space with our creative designs." />
                <meta name="keywords" content="Interior in Andheri,Custom Interior Designers in Andheri,Interior Design Services in Andheri,Best Interior Designer in Andheri,Top Architecture in Andheri,Interior Designing With Furnishing Services Andheri,Interior Decorator in Andheri,Interior Designer near Andheri,Best Interior Designer in Andheri,Top Interior Designer in Andheri,Architecture in Andheri,Top commercial architecture designer in andheri,Interior Designing With Furnishing Services Andheri" />
                <link rel="canonical" href="https://thehivemakers.com/interior-designers-near-andheri" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Interior Designer near Andheri | HIVE" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://thehivemakers.com/interior-designers-near-andheri" />
                <meta
                    property="og:description"
                    content="Explore expert Interior Designers near Andheri offering innovative solutions for your home. Get inspired and transform your space with our creative designs."
                />
                <meta
                    property="og:image"
                    content="https://thehivemakers.com/static/media/Logo2.d18c2a41e337393532b6.png"
                />
            </MetaTags>
            <section className="contact-Section-1">
                <div className="container">
                    <div className="contact-Section-con">
                        <div className="contact-Section-Text">
                            <h1>Interior Designer near Andheri</h1>
                            <div className="contact-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='Contact-Page-Form-And-Details-con'>
                <div className='container'>
                    <h2 className='d-none'>Looking for an Interior Designer near Andheri to bring your design dreams to life? Look no further than Hive Interior Designer. Our team is dedicated to delivering unparalleled design solutions tailored to your unique style and preferences. Whether you're revamping your home or office space, we're here to help. Contact us today to schedule a consultation and let us turn your vision into reality. With Hive Interior Designer, your dream space is just a call away.</h2>
                    <div className='Contact-Page-Details-con'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='Contact-Page-Details-Info-con'>
                                    <div className='Contact-Page-Details-Head-con'>
                                        <h3>Contact with us</h3>
                                        <p>Connect with Hive - Your Trusted Interior Designer near Andheri. Let's Craft Your Dream Space Together. Reach out to us for personalized consultations, innovative designs, and expert solutions to transform your home or office into a haven of style and functionality.</p>
                                    </div>

                                    <div className='Contact-Page-Details-contact-con'>
                                        <div className='Contact-Page-Details-Icons-Info-con'>
                                            <IoIosCall />
                                            <div className='Contact-Page-Details-Info'>
                                                <h4>Call us now</h4>
                                                <h5><a href="tel:+919653460781">+91 9653-460-781</a></h5>
                                                <h5><a href="tel:++919653468744">+91 9653-468-744</a></h5>
                                            </div>
                                        </div>

                                        <div className='Contact-Page-Details-Icons-Info-con'>
                                            <IoIosMail />
                                            <div className='Contact-Page-Details-Info'>
                                                <h4>Send email</h4>
                                                <h5><a href="mailto: ceo@hivebuilders.in">ceo@hivebuilders.in</a></h5>
                                                <h5><a href="mailto: constructionhive@gmail.com">constructionhive@gmail.com</a></h5>
                                            </div>
                                        </div>

                                        <div className='Contact-Page-Details-Icons-Info-con'>
                                            <SiGooglemaps />
                                            <div className='Contact-Page-Details-Info'>
                                                <h4>Our location</h4>
                                                <h5><a href='https://maps.app.goo.gl/VWGF3g3kcX2K66Ft6' target='blank'>1603, Range Heights, Behram Baug Rd, Kadam Nagar ,Jogeshwari (West),Mumbai ,Maharashtra-400102</a></h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <section className='Contact-page-Form-Con'>
                                    <div className='Contact-page-Form-Head-Con'>
                                        <h2>Get in Touch</h2>
                                    </div>
                                    <div className='Contact-Page-Form-Inputs-con'>
                                        <Form layout='vertical' onFinish={submitfrom}>
                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item
                                                    label="Enter name"
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your name!',
                                                        },
                                                    ]}
                                                >
                                                    <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                        <Input type="text" name="fname" placeholder='Enter your name' />
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item
                                                    label="Enter number"
                                                    name='contact'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Contact!',
                                                        },
                                                    ]}
                                                >
                                                    <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                        <Input type="number" placeholder='Enter your phone number' />
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item
                                                    label="Select Service"
                                                    name="service"
                                                    rules={[{ required: true, message: "Please select a service" }]}
                                                >
                                                        <Select
                                                            placeholder="Select Service"
                                                            allowClear
                                                        >
                                                            <Select.Option value="Residential Interior">Residential Interior</Select.Option>
                                                            <Select.Option value="Commercial Interior">Commercial Interior</Select.Option>
                                                            <Select.Option value="Residential Architecture">Residential Architecture</Select.Option>
                                                            <Select.Option value="Commercial Architecture">Commercial Architecture</Select.Option>
                                                            <Select.Option value="Furniture Frenzy">Furniture Frenzy</Select.Option>
                                                        </Select>
                                                </Form.Item>
                                            </div>

                                            <div className='Contact-Page-Form-Inputs'>
                                                <Form.Item
                                                    label="Message"
                                                    name="message"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Message',
                                                        },
                                                    ]}
                                                >
                                                    <div className='Like-to-pay-form-con-Card-Info-Input'>
                                                        <Input.TextArea type="text" id="fname" name="fname" placeholder='Enter your message' />
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <div className='Contact-Page-Form-button'>
                                                <button>
                                                    Sent Message
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='Contact-Page-Map-Section'>
                <div className='container'>
                    <div className='Contact-Page-Map-Section-con'>
                        <div className='Contact-Page-Map-Head-Section-con'>
                            <h2>View your location</h2>
                        </div>

                        <div className='Contact-Page-Map-Section-con-frem'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15076.560229406381!2d72.8366156!3d19.1453456!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7b35b5e866d%3A0xae49d80bf069664f!2sHive%20Construction%20%26%20Builders-%20Architects%2C%20Interior%20Designers%2C%20%26%20Civil%20Contractors%20in%20Mumbai!5e0!3m2!1sen!2sin!4v1713427757153!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Contact