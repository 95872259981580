import React from "react";
import "../Footer/FooterPage.css";
import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import Hive from "../../Images/Logo/Hive.png";
const FooterPage = () => {
  return (
    <>
      <section className="Footer-Main-Section">
        <section className="Footer-Section-con">
          <div className="container">
            <div className="Footer-Section-Details">
              <div className="footer-section-logo-main-con">
                <Link to="/">
                  <div className="footer-section-logo-main">
                    <img src={Hive} alt="Hive Interior Design" />
                  </div>
                </Link>
              </div>

              <div className="footer-section-footer-menu-con">
                <div className="footer-section-footer-menu">
                  <Link to="/">
                    <h3>Home</h3>
                  </Link>
                  <Link to="/custom-interior-designers-andheri">
                    <h3>About</h3>
                  </Link>
                  <Link to="/interior-design-services-andheri">
                    <h3>Service</h3>
                  </Link>
                  <Link to="/interior-decorator-andheri">
                    <h3>Gallery</h3>
                  </Link>
                  <Link to='/interior-designers-near-andheri'>
                    <h3>Contact</h3>
                  </Link>
                </div>
              </div>

              <div className="footer-section-footer-address-con">
                <p>
                  1603, Range Heights, Behram Baug Rd, Kadam Nagar ,Jogeshwari
                  (West),{" "}
                </p>
                <p>Mumbai ,Maharashtra-400102</p>
              </div>

              <div className="footer-section-footer-contact-con">
                <div
                  className="footer-section-footer-contact "
                  id="footer-contact"
                >
                  <a href="mailto:ceo@hivebuilders.in">
                    <h3>ceo@hivebuilders.in</h3>
                  </a>
                  <a href="tel:+919653460781">
                    <h3>+91 9653-460-781</h3>
                  </a>
                </div>
              </div>
              <div className="footer-section-footer-contact-con">
                <div
                  className="footer-section-footer-contact"
                  id="footer-contact1"
                >
                  <a href="mailto:constructionhive@gmail.com">
                    <h3>constructionhive@gmail.com</h3>
                  </a>
                  <a href="tel:+919653468744">
                    <h3>+91 9653-468-744</h3>
                  </a>
                </div>
              </div>

              <div className="footer-section-footer-social-media-main-con">
                <div
                  className="footer-section-footer-social-media-main"
                  id="media"
                >
                  <a href='https://www.instagram.com/hivemakers/' target='blank'>
                    <AiFillInstagram />
                  </a>
                  <a href='https://www.facebook.com/hivemakers/' target='blank'>
                    <FaFacebookSquare />
                  </a>
                  <a href="mailto: ceo@hivebuilders.in" target='blank'>
                    <IoIosMail />
                  </a>
                  <a href="tel:+919653460781">
                    <IoCall />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Footer-copy-right-section-con">
          <div className="Footer-copy-right-section">
            <p>
              <span id="span1">Copyright © 2024, All Right Reserved</span>
              <span id="span" className="footer-name"> Hive</span>
            </p>
            <p className="p">
              Designed by
              <a href="https://skdm.in/" target="blank">
                Shree Krishna Digital Marketing
              </a>
            </p>
          </div>
        </section>
      </section>
    </>
  );
};

export default FooterPage;
