import React, { useState, useEffect } from "react";
import "../Gallery/Tabs.css";
import Zoom from "react-reveal/Zoom";

const Tabs = ({ filterCategory, tabsData }) => {
  const [activeCategory, setActiveCategory] = useState(tabsData[0]);

  useEffect(() => {
    filterCategory(activeCategory);
  }, []);

  const handleCategoryClick = (category) => {
    filterCategory(category);
    setActiveCategory(category);
  };

  return (
    <>
      <div className="Tabs-container">
        <Zoom right cascade>
          <div className="text-center" id="tabsAll">
            {tabsData.map((category, index) => {
              return (
                <button
                  type="button"
                  className={`btn my-1 mx-1 text-capitalize ButtonG ${
                    activeCategory === category ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick(category)}
                  key={index}
                >
                  <u>{category}</u>
                </button>
              );
            })}
          </div>
        </Zoom>
      </div>
    </>
  );
};

export default Tabs;
