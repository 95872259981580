import React from "react";
import "../Team/HomeTeam.css";
import { PiPlusThin } from "react-icons/pi";
import { RiWhatsappFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
import ADNANARIFBAWA from "../../Images/Team/ADNAN ARIF BAWA.png";
import SAKSHIPRAVINSAWANT from "../../Images/Team/SAKSHI PRAVIN SAWANT.jpeg";
import KOMALMODI from "../../Images/Team/KOMAL MODI.jpeg";
import SHAHMIRKAIF from "../../Images/Team/SHAHMIR KAIF.png";
import LAXMANJK from "../../Images/Team/LAXMAN JK.png";
import Shoaibkhatri from "../../Images/Team/NARAYAN.png";
import ASHISHSHIRSATH from "../../Images/Team/ASHISH SHIRSATH.png";
import  SHAIRISHSIDDIQUE from "../../Images/Team/SHAIRISH SIDDIQUE.png";
import  TASLIMSHAIKH from "../../Images/Team/TASLIM SHAIKH.png";
import Shaikhnuhid from "../../Images/Team/Shaikh nuhid.jpeg";
import ShahidKhan from "../../Images/Team/Shahid Khan.PNG";
const HomeTeam = () => {
  return (
    <>
      <section className="about-section-2-con">
        <div className="container">
          <div className="About-Section-2-head">
            <div className="row">

              <div className="col-md-7">

                <div className='About-Section-2-head-title'>
                  <h2>Team Members</h2>
                </div>

              </div>

              <div className="col-md-5">
                <div className="About-Section-2-head-title">
                  <p>
                    Through a unique combination of engineering, construction
                    and design disciplines and expertise.
                  </p>
                </div>
              </div>

            </div>

          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={ADNANARIFBAWA} alt="ADNAN ARIF BAWA" />
                  <div className="about-section-2-card-details">
                    <h3>ADNAN ARIF BAWA</h3>
                    <p>Architectural Designer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={SAKSHIPRAVINSAWANT} alt="SAKSHI PRAVIN SAWANT" />
                  <div className="about-section-2-card-details">
                    <h3>SAKSHI PRAVIN SAWANT</h3>
                    <p>Interior Designer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={KOMALMODI} alt="KOMAL MODI" />
                  <div className="about-section-2-card-details">
                    <h3>KOMAL MODI</h3>
                    <p>Interior Designer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={SHAHMIRKAIF} alt="SHAHMIR KAIF" />
                  <div className="about-section-2-card-details">
                    <h3>SHAHMIR KAIF</h3>
                    <p>Site Supervision 10 Yr Experience Worked In Abroad</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={LAXMANJK} alt="LAXMAN JK" />
                  <div className="about-section-2-card-details">
                    <h3>LAXMAN JK</h3>
                    <p>Carpenter 30 Yr Experience And Worked In Abroad </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={Shoaibkhatri} alt="Shoaib khatri" />
                  <div className="about-section-2-card-details">
                    <h3>Shoaib khatri </h3>
                    <p>Senior Architect</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={ASHISHSHIRSATH} alt="ASHISH SHIRSATH" />
                  <div className="about-section-2-card-details">
                    <h3>ASHISH SHIRSATH</h3>
                    <p>Material Cordinator</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={Shaikhnuhid} alt="SHAIRISH SIDDIQUE" />
                  <div className="about-section-2-card-details">
                    <h3>Shaikh nuhid</h3>
                    <p>Architect</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={ShahidKhan} alt="TASLIM SHAIKH" />
                  <div className="about-section-2-card-details">
                    <h3>Shahid Khan</h3>
                    <p>Team Leader</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="about-section-2-card-con">
                <div className="about-section-2-card-body">
                  <img src={TASLIMSHAIKH} alt="TASLIM SHAIKH" />
                  <div className="about-section-2-card-details">
                    <h3>TASLIM SHAIKH</h3>
                    <p>Site Cordinator</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTeam;

