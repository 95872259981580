import React from 'react';
import "../Gallery/Item.css";
import Zoom from 'react-reveal/Zoom';
import { Image } from 'antd';
const Items = ({ data }) => {

    // const slicedArray = data.slice(0, 12);

    return (
        <>
            <div className='row'>
                {
                    data.map((value) => {
                        const { id, CImage, Galleryalt } = value

                        return (
                            <>
                                <Zoom>
                                    <div className="col-lg-4 col-md-6 my-3" key={id}>
                                        <div className="card bg-light text-center">
                                            <Image src={CImage} alt={Galleryalt}/>
                                            {/* <div className='Card-text'>
                                                <h2>{CategoryName}</h2>
                                            </div> */}
                                        </div>
                                    </div>
                                </Zoom>
                            </>
                        )
                    })
                }

            </div>


        </>
    )
}

export default Items