import BhargaviSawant from "../../Images/Testimonial/Bhargavi Sawant.png";
import SuchiMishra from "../../Images/Testimonial/Suchi Mishra.avif";
import ChetanaSuryawanshi from "../../Images/Testimonial/Chetana Suryawanshi.png";
import AtulTiwari from "../../Images/Testimonial/Atul Tiwari.png";
import CharulBandre from "../../Images/Testimonial/Charul Bandre.png";
import profile from "../../Images/Testimonial/demo profile.png";

const TestimonialData = [
    {
        TestimonialName: "Bhargavi Sawant",
        TestimonialImage:BhargaviSawant,
        TestimonialDes:"Best residential construction company in Mumbai",
    },
    {
        TestimonialName: "Suchi Mishra",
        TestimonialImage:SuchiMishra,
        TestimonialDes:"Best commercial office construction company in Jogeshwari",
    },
    {
        TestimonialName: "Madhu Pandey",
        TestimonialImage:profile,
        TestimonialDes:"Best civil construction companies in Mumbai",
    },
    {
        TestimonialName: "Vruti Vadera",
        TestimonialImage:profile,
        TestimonialDes:"Best construction and builders in Mumbai",
    },
    {
        TestimonialName: "Chetana Suryawanshi",
        TestimonialImage:ChetanaSuryawanshi,
        TestimonialDes:"Top construction Company in Mumbai",
    },
    {
        TestimonialName: "Atul Tiwari",
        TestimonialImage:AtulTiwari,
        TestimonialDes:"Best architect in Mumbai",
    },
    {
        TestimonialName: "Charul Bandre",
        TestimonialImage:CharulBandre,
        TestimonialDes:"Best interior designer in Jogeshwari",
    },
]

export default TestimonialData;
