import React from 'react';
import "../OurMission/Mission.css";

const Mission = () => {
    return (
        <>
            <section className='About-Section-Mission-Main-con'>
                <div className='container'>
                    <div className='About-Section-Mission-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>01</h2>
                                    <h3>Concept Design</h3>
                                    <p>
                                        Explore innovative concept designs crafted by Custom Interior Designers in Andheri, blending functionality with aesthetics to create spaces that inspire. Discover the latest trends and visionary approaches in interior and architecture, shaping the future of design.
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>02</h2>
                                    <h3>Schematic Drawings</h3>
                                    <p>Dive into the world of schematic drawings with Custom Interior Designers in Andheri, where ideas take shape. Discover the power of visual storytelling and the precision of technical details in architectural and interior design.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='About-Section-Deatils-con'>
                                    <h2>03</h2>
                                    <h3>Project Offer</h3>
                                    <p>Explore our tailored project offers with Custom Interior Designers in Andheri, crafted to bring your vision to life. From concept to completion, we specialize in creating bespoke solutions for your architectural and interior design needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Mission