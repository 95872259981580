import React from 'react';
import '../Process/HomeProcess.css';
import StickyBox from 'react-sticky-box';

const HomeProcess = () => {
    return (
        <section className='Home-Process-Section'>
            <div className='container'>
                <div className='Home-Section-Process-main-con'>
                    <div className='row'>
                        <div className='col-md-5'>
                            <StickyBox>
                                <div className='Home-Section-Process-head-con'>
                                    <h2 className='Head-Style-Service'>
                                        The Process
                                    </h2>
                                    <p>
                                    Through a unique combination of engineering, construction, and design disciplines, and expertise in interior in Andheri.
                                    </p>
                                </div>
                            </StickyBox>
                        </div>
                        <div className='col-md-7'>
                            <div className='Home-Section-Prosses-Details-con'>
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <div className='Home-Section-Prosses-number'>
                                            <h2>01</h2>
                                        </div>
                                        <div className='Home-Section-Prosses-details-info'>
                                            <h3>Concept Design_</h3>
                                            <p>Transforming ideas into stunning designs, merging creativity with practicality seamlessly.</p>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='Home-Section-Prosses-number'>
                                            <h2>02</h2>
                                        </div>
                                        <div className='Home-Section-Prosses-details-info'>
                                            <h3>Schematic Design_</h3>
                                            <p>Initial blueprints capturing essence, guiding development of architectural masterpieces meticulously.</p>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='Home-Section-Prosses-number'>
                                            <h2>03</h2>
                                        </div>
                                        <div className='Home-Section-Prosses-details-info'>
                                            <h3>Construction Drawings_</h3>
                                            <p>Detailed plans facilitating precise execution, ensuring realization of architectural visions.</p>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='Home-Section-Prosses-number'>
                                            <h2>04</h2>
                                        </div>
                                        <div className='Home-Section-Prosses-details-info'>
                                            <h3>Project Administration</h3>
                                            <p>Efficiently overseeing projects, coordinating tasks to ensure seamless execution and completion.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeProcess