import React from 'react';
import "../About/HomeAbout.css";
import homeabout01 from "../../Images/Pages/Home/HomeAbout01.webp";

const HomeAbout = () => {
    return (
        <>
            <section className='home-about-section-con'>
                <div className='container'>
                    <div className='about-section-image-details'>
                        <div className='row'>
                            <div className='col-md-6 p-0'>
                                <div class="about-us-one-left">
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src="https://www.youtube.com/embed/pKwkhpLOMOw?si=O_3nU1a6suAVQr7B&autoplay=1&mute=1&loop=1&playlist=pKwkhpLOMOw"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <div className='col-md-6 p-0'>
                                <div className='about-section-page-about-details-con'>
                                    <div className='about-section-page-about-details'>
                                        <div className='about-section-page-about-info'>
                                            <h3>STUDIO <span>B.E.E</span></h3>
                                            <h4><span className='studiobee'>Beautiful</span> <span className='studiobee'>Elegant</span> <span className='studiobee'>Efficient</span></h4>
                                            <p>At Studio B.E.E., we aim to create and innovate designs inspired by culture. Just as breeds of honey bees vary from place to place, so do the textures of the honey and the beehives. The beauty of every process and the end product is equally breathtaking. Specializing in interior in Andheri, we bring this same diversity and beauty to our designs.</p>
                                            <p>We strive to work as hard and effectively as the bee, leaving you with interior designs in Andheri that will imprint these three words into your mind: creativity, elegance, and functionality.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default HomeAbout