// furniture 
import furniture01 from "../../Images/Pages/Gallery/furniturefrenzy/furniturefrenzy03.webp";
import furniture02 from "../../Images/Pages/Gallery/furniturefrenzy/furniturefrenzy04.webp";
import furniture03 from "../../Images/Pages/Gallery/furniturefrenzy/furniturefrenzy05.webp";
import furniture04 from "../../Images/Pages/Gallery/furniturefrenzy/furniturefrenzy06.webp";
import furniture05 from "../../Images/Pages/Gallery/furniturefrenzy/furniturefrenzy08.webp";
import furniture06 from "../../Images/Pages/Gallery/furniturefrenzy/furniturefrenzy09.webp";

// Residential Interior
import ResidentialInterior01 from "../../Images/Pages/Gallery/residential interior design/residentialinterior1.webp";
import ResidentialInterior02 from "../../Images/Pages/Gallery/residential interior design/residentialinterior2.webp";
import ResidentialInterior03 from "../../Images/Pages/Gallery/residential interior design/residentialinterior3.webp";
import ResidentialInterior04 from "../../Images/Pages/Gallery/residential interior design/residentialinterior4.webp";
import ResidentialInterior05 from "../../Images/Pages/Gallery/residential interior design/residentialinterior5.webp";
import ResidentialInterior06 from "../../Images/Pages/Gallery/residential interior design/residentialinterior6.webp";
import ResidentialInterior07 from "../../Images/Pages/Gallery/residential interior design/residentialinterior7.webp";
import ResidentialInterior08 from "../../Images/Pages/Gallery/residential interior design/residentialinterior8.webp";
import ResidentialInterior09 from "../../Images/Pages/Gallery/residential interior design/residentialinterior9.webp";
import ResidentialInterior10 from "../../Images/Pages/Gallery/residential interior design/residentialinterior10.webp";
import ResidentialInterior11 from "../../Images/Pages/Gallery/residential interior design/residentialinterior11.webp";
import ResidentialInterior12 from "../../Images/Pages/Gallery/residential interior design/residentialinterior12.webp";
import ResidentialInterior13 from "../../Images/Pages/Gallery/residential interior design/residentialinterior13.webp";
import ResidentialInterior14 from "../../Images/Pages/Gallery/residential interior design/residentialinterior14.webp";
import ResidentialInterior15 from "../../Images/Pages/Gallery/residential interior design/residentialinterior15.webp";
import ResidentialInterior16 from "../../Images/Pages/Gallery/residential interior design/residentialinterior16.webp";
import ResidentialInterior17 from "../../Images/Pages/Gallery/residential interior design/residentialinterior17.webp";
import ResidentialInterior18 from "../../Images/Pages/Gallery/residential interior design/residentialinterior18.webp";
import ResidentialInterior19 from "../../Images/Pages/Gallery/residential interior design/residentialinterior19.webp";
import ResidentialInterior20 from "../../Images/Pages/Gallery/residential interior design/residentialinterior20.webp";
import ResidentialInterior21 from "../../Images/Pages/Gallery/residential interior design/residentialinterior21.webp";
import ResidentialInterior22 from "../../Images/Pages/Gallery/residential interior design/residentialinterior22.webp";
import ResidentialInterior23 from "../../Images/Pages/Gallery/residential interior design/residentialinterior23.webp";
import ResidentialInterior24 from "../../Images/Pages/Gallery/residential interior design/residentialinterior24.webp";
import ResidentialInterior25 from "../../Images/Pages/Gallery/residential interior design/residentialinterior25.webp";
import ResidentialInterior26 from "../../Images/Pages/Gallery/residential interior design/residentialinterior26.webp";
import ResidentialInterior27 from "../../Images/Pages/Gallery/residential interior design/residentialinterior27.webp";
import ResidentialInterior28 from "../../Images/Pages/Gallery/residential interior design/residentialinterior28.webp";
import ResidentialInterior29 from "../../Images/Pages/Gallery/residential interior design/residentialinterior29.webp";
import ResidentialInterior30 from "../../Images/Pages/Gallery/residential interior design/residentialinterior30.webp";
import ResidentialInterior31 from "../../Images/Pages/Gallery/residential interior design/residentialinterior31.webp";
import ResidentialInterior32 from "../../Images/Pages/Gallery/residential interior design/residentialinterior32.webp";
import ResidentialInterior33 from "../../Images/Pages/Gallery/residential interior design/residentialinterior33.webp";
import ResidentialInterior34 from "../../Images/Pages/Gallery/residential interior design/residentialinterior34.webp";
import ResidentialInterior35 from "../../Images/Pages/Gallery/residential interior design/residentialinterior35.webp";
import ResidentialInterior36 from "../../Images/Pages/Gallery/residential interior design/residentialinterior36.webp";
import ResidentialInterior37 from "../../Images/Pages/Gallery/residential interior design/residentialinterior37.webp";
import ResidentialInterior38 from "../../Images/Pages/Gallery/residential interior design/residentialinterior38.webp";
import ResidentialInterior39 from "../../Images/Pages/Gallery/residential interior design/residentialinterior39.webp";
import ResidentialInterior40 from "../../Images/Pages/Gallery/residential interior design/residentialinterior40.webp";
import ResidentialInterior41 from "../../Images/Pages/Gallery/residential interior design/residentialinterior41.webp";


// Residential Architecture 

import ResidentialArchitecture01 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture1.webp";
import ResidentialArchitecture02 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture2.webp";
import ResidentialArchitecture03 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture3.webp";
import ResidentialArchitecture04 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture4.webp";
import ResidentialArchitecture05 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture5.webp";
import ResidentialArchitecture06 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture6.webp";
import ResidentialArchitecture07 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture7.webp";
import ResidentialArchitecture08 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture8.webp";
import ResidentialArchitecture09 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture9.webp";
import ResidentialArchitecture10 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture10.webp";
import ResidentialArchitecture11 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture11.webp";
import ResidentialArchitecture12 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture12.webp";
import ResidentialArchitecture13 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture13.webp";
import ResidentialArchitecture14 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture14.webp";
import ResidentialArchitecture15 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture15.webp";
import ResidentialArchitecture16 from "../../Images/Pages/Gallery/residential architecture design/ResidentialArchitecture16.webp";

// Commercial Interior 

import CommercialInterior01 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign1.webp";
import CommercialInterior02 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign2.webp";
import CommercialInterior03 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign3.webp";
import CommercialInterior04 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign4.webp";
import CommercialInterior05 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign5.webp";
import CommercialInterior06 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign6.webp";
import CommercialInterior07 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign7.webp";
import CommercialInterior08 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign8.webp";
import CommercialInterior09 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign9.webp";
import CommercialInterior10 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign10.webp";
import CommercialInterior11 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign11.webp";
import CommercialInterior12 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign12.webp";
import CommercialInterior13 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign13.webp";
import CommercialInterior14 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign14.webp";
import CommercialInterior15 from "../../Images/Pages/Gallery/commercialinteriordesign/commercialinteriordesign15.webp";


// Commercial Architecture 

import CommercialArchitecture03 from "../../Images/Pages/Gallery/commercialarchitecture/commercialarchitecture8.webp";
import CommercialArchitecture04 from "../../Images/Pages/Gallery/commercialarchitecture/commercialarchitecture9.webp";
import CommercialArchitecture08 from "../../Images/Pages/Gallery/commercialarchitecture/commercialarchitecture13.webp";
import CommercialArchitecture09 from "../../Images/Pages/Gallery/commercialarchitecture/commercialarchitecture14.webp";
import CommercialArchitecture10 from "../../Images/Pages/Gallery/commercialarchitecture/commercialarchitecture15.webp";
import CommercialArchitecture11 from "../../Images/Pages/Gallery/commercialarchitecture/commercialarchitecture16.webp";

const GalleryData = [
  {
    id: 1,
    Category: "Furniture",
    CImage: furniture01,
    Galleryalt: "Luxury interiors in Andheri",
  },
  {
    id: 2,
    Category: "Furniture",
    CImage: furniture02,
    Galleryalt: "Modern home decoration in Andheri",
  },
  {
    id: 3,
    Category: "Furniture",
    CImage: furniture03,
    Galleryalt: "Custom furniture design in Andheri",
  },
  {
    id: 4,
    Category: "Furniture",
    CImage: furniture04,
    Galleryalt: "High-end architecture in Andheri",
  },
  {
    id: 5,
    Category: "Furniture",
    CImage: furniture05,
    Galleryalt: "Residential architects in Andheri",
  },
  {
    id: 6,
    Category: "Furniture",
    CImage: furniture06,
    Galleryalt: "Commercial interiors in Andheri",
  },
  {
    id: 7,
    Category: "Residential Interior",
    CImage: ResidentialInterior01,
    Galleryalt: "Boutique design services in Andheri",
  },
  {
    id: 8,
    Category: "Residential Interior",
    CImage: ResidentialInterior02,
    Galleryalt: "Interior decorators in Andheri",
  },
  {
    id: 9,
    Category: "Residential Interior",
    CImage: ResidentialInterior03,
    Galleryalt: "Contemporary kitchens in Andheri",
  },
  {
    id: 10,
    Category: "Residential Interior",
    CImage: ResidentialInterior04,
    Galleryalt: "Sustainable design in Andheri",
  },
  {
    id: 11,
    Category: "Residential Interior",
    CImage: ResidentialInterior05,
    Galleryalt: "Smart home solutions in Andheri",
  },
  {
    id: 12,
    Category: "Residential Interior",
    CImage: ResidentialInterior06,
    Galleryalt: "Interior design trends in Andheri",
  },
  {
    id: 13,
    Category: "Residential Interior",
    CImage: ResidentialInterior07,
    Galleryalt: "Custom interiors in Andheri",
  },
  {
    id: 14,
    Category: "Residential Interior",
    CImage: ResidentialInterior08,
    Galleryalt: "Minimalist architecture in Andheri",
  },
  {
    id: 15,
    Category: "Residential Interior",
    CImage: ResidentialInterior09,
    Galleryalt: "Design studios in Andheri",
  },
  {
    id: 16,
    Category: "Residential Interior",
    CImage: ResidentialInterior10,
    Galleryalt: "Renovation experts in Andheri",
  },
  {
    id: 17,
    Category: "Residential Interior",
    CImage: ResidentialInterior11,
    Galleryalt: "Luxury bathroom design in Andheri",
  },
  {
    id: 18,
    Category: "Residential Interior",
    CImage: ResidentialInterior12,
    Galleryalt: "Office space planning in Andheri",
  },
  {
    id: 19,
    Category: "Residential Interior",
    CImage: ResidentialInterior13,
    Galleryalt: "Retail design in Andheri",
  },
  {
    id: 20,
    Category: "Residential Interior",
    CImage: ResidentialInterior14,
    Galleryalt: "Property staging in Andheri",
  },
  {
    id: 21,
    Category: "Residential Interior",
    CImage: ResidentialInterior15,
    Galleryalt: "Artistic interiors in Andheri",
  },
  {
    id: 22,
    Category: "Residential Interior",
    CImage: ResidentialInterior16,
    Galleryalt: "Textile trends in Andheri",
  },
  {
    id: 23,
    Category: "Residential Interior",
    CImage: ResidentialInterior17,
    Galleryalt: "Flooring installations in Andheri",
  },
  {
    id: 24,
    Category: "Residential Interior",
    CImage: ResidentialInterior18,
    Galleryalt: "Innovative lighting in Andheri",
  },
  {
    id: 25,
    Category: "Residential Interior",
    CImage: ResidentialInterior19,
    Galleryalt: "Interior design consultations in Andheri",
  },
  {
    id: 26,
    Category: "Residential Interior",
    CImage: ResidentialInterior20,
    Galleryalt: "Cabinetry customization in Andheri",
  },
  {
    id: 27,
    Category: "Residential Interior",
    CImage: ResidentialInterior21,
    Galleryalt: "Wall treatments in Andheri",
  },
  {
    id: 28,
    Category: "Residential Interior",
    CImage: ResidentialInterior22,
    Galleryalt: "Fabric selection experts in Andheri",
  },
  {
    id: 29,
    Category: "Residential Interior",
    CImage: ResidentialInterior23,
    Galleryalt: "Color consultants in Andheri",
  },
  {
    id: 30,
    Category: "Residential Interior",
    CImage: ResidentialInterior24,
    Galleryalt: "Design workshops in Andheri",
  },
  {
    id: 31,
    Category: "Residential Interior",
    CImage: ResidentialInterior25,
    Galleryalt: "Design exhibitions in Andheri",
  },
  {
    id: 32,
    Category: "Residential Interior",
    CImage: ResidentialInterior26,
    Galleryalt: "Home architects in Andheri",
  },
  {
    id: 33,
    Category: "Residential Interior",
    CImage: ResidentialInterior27,
    Galleryalt: "Luxury property design in Andheri",
  },
  {
    id: 34,
    Category: "Residential Interior",
    CImage: ResidentialInterior28,
    Galleryalt: "Investment property interiors in Andheri",
  },
  {
    id: 35,
    Category: "Residential Interior",
    CImage: ResidentialInterior29,
    Galleryalt: "Design innovations in Andheri",
  },
  {
    id: 36,
    Category: "Residential Interior",
    CImage: ResidentialInterior30,
    Galleryalt: "Cultural interior design in Andheri",
  },
  {
    id: 37,
    Category: "Residential Interior",
    CImage: ResidentialInterior31,
    Galleryalt: "Urban landscape design in Andheri",
  },
  {
    id: 38,
    Category: "Residential Interior",
    CImage: ResidentialInterior32,
    Galleryalt: "Interior design awards in Andheri",
  },
  {
    id: 39,
    Category: "Residential Interior",
    CImage: ResidentialInterior33,
    Galleryalt: "Design education programs in Andheri",
  },
  {
    id: 40,
    Category: "Residential Interior",
    CImage: ResidentialInterior34,
    Galleryalt: "Upcoming designers in Andheri",
  },
  {
    id: 41,
    Category: "Residential Interior",
    CImage: ResidentialInterior35,
    Galleryalt: "Creative studios in Andheri",
  },
  {
    id: 42,
    Category: "Residential Interior",
    CImage: ResidentialInterior36,
    Galleryalt: "Design networking in Andheri",
  },
  {
    id: 43,
    Category: "Residential Interior",
    CImage: ResidentialInterior37,
    Galleryalt: "Project management in Andheri",
  },
  {
    id: 44,
    Category: "Residential Interior",
    CImage: ResidentialInterior38,
    Galleryalt: "Turnkey interior solutions in Andheri",
  },
  {
    id: 45,
    Category: "Residential Interior",
    CImage: ResidentialInterior39,
    Galleryalt: "Interior consultancy in Andheri",
  },
  {
    id: 46,
    Category: "Residential Interior",
    CImage: ResidentialInterior40,
    Galleryalt: "Art installations in Andheri",
  },
  {
    id: 47,
    Category: "Residential Interior",
    CImage: ResidentialInterior41,
    Galleryalt: "Heritage interior restoration in Andheri",
  },
  {
    id: 48,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture01,
    Galleryalt: "Transformation projects in Andheri",
  },
  {
    id: 49,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture02,
    Galleryalt: "Design portfolio in Andheri",
  },
  {
    id: 50,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture03,
    Galleryalt: "Co-working space design in Andheri",
  },
  {
    id: 51,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture04,
    Galleryalt: "Youthful decor in Andheri",
  },
  {
    id: 52,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture05,
    Galleryalt: "Vibrant interiors in Andheri",
  },
  {
    id: 53,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture06,
    Galleryalt: "Zen-inspired designs in Andheri",
  },
  {
    id: 54,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture07,
    Galleryalt: "Acoustic solutions in Andheri",
  },
  {
    id: 55,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture08,
    Galleryalt: "Thermal comfort design in Andheri",
  },
  {
    id: 56,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture09,
    Galleryalt: "Furniture innovations in Andheri",
  },
  {
    id: 57,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture10,
    Galleryalt: "Organizational solutions in Andheri",
  },
  {
    id: 58,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture11,
    Galleryalt: "Design seminars in Andheri",
  },
  {
    id: 59,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture12,
    Galleryalt: "Architecture consultancy in Andheri",
  },
  {
    id: 60,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture13,
    Galleryalt: "Architecture tours in Andheri",
  },
  {
    id: 61,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture14,
    Galleryalt: "Design technology in Andher",
  },
  {
    id: 62,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture15,
    Galleryalt: "Automated home systems in Andheri",
  },
  {
    id: 63,
    Category: "Residential Architecture",
    CImage: ResidentialArchitecture16,
    Galleryalt: "Design podcasts in Andheri",
  },
  {
    id: 64,
    Category: "Commercial Interior",
    CImage: CommercialInterior01,
    Galleryalt: "Design influencers in Andheri",
  },
  {
    id: 65,
    Category: "Commercial Interior",
    CImage: CommercialInterior02,
    Galleryalt: "Interior design inspiration in Andheri",
  },
  {
    id: 66,
    Category: "Commercial Interior",
    CImage: CommercialInterior03,
    Galleryalt: "DIY projects in Andheri",
  },
  {
    id: 67,
    Category: "Commercial Interior",
    CImage: CommercialInterior04,
    Galleryalt: "Interior design magazines in Andheri",
  },
  {
    id: 68,
    Category: "Commercial Interior",
    CImage: CommercialInterior05,
    Galleryalt: "Home improvement tips in Andheri",
  },
  {
    id: 69,
    Category: "Commercial Interior",
    CImage: CommercialInterior06,
    Galleryalt: "Green building techniques in Andheri",
  },
  {
    id: 70,
    Category: "Commercial Interior",
    CImage: CommercialInterior07,
    Galleryalt: "Eco-friendly materials in Andheri",
  },
  {
    id: 71,
    Category: "Commercial Interior",
    CImage: CommercialInterior08,
    Galleryalt: "Luxury estate interiors in Andheri",
  },
  {
    id: 72,
    Category: "Commercial Interior",
    CImage: CommercialInterior09,
    Galleryalt: "Contemporary furniture in Andheri",
  },
  {
    id: 73,
    Category: "Commercial Interior",
    CImage: CommercialInterior10,
    Galleryalt: "Historical renovations in Andheri",
  },
  {
    id: 74,
    Category: "Commercial Interior",
    CImage: CommercialInterior11,
    Galleryalt: "Lighting design in Andheri",
  },
  {
    id: 75,
    Category: "Commercial Interior",
    CImage: CommercialInterior12,
    Galleryalt: "Spatial optimization in Andheri",
  },
  {
    id: 76,
    Category: "Commercial Interior",
    CImage: CommercialInterior13,
    Galleryalt: "Interior symmetry in Andheri",
  },
  {
    id: 77,
    Category: "Commercial Interior",
    CImage: CommercialInterior14,
    Galleryalt: "Natural light design in Andheri",
  },
  {
    id: 78,
    Category: "Commercial Interior",
    CImage: CommercialInterior15,
    Galleryalt: "Building permits in Andheri",
  },
  {
    id: 79,
    Category: "Commercial Architecture",
    CImage: CommercialArchitecture03,
    Galleryalt: "Construction management in Andheri",
  },
  {
    id: 80,
    Category: "Commercial Architecture",
    CImage: CommercialArchitecture04,
    Galleryalt: "Cost-effective design in Andheri",
  },
  {
    id: 81,
    Category: "Commercial Architecture",
    CImage: CommercialArchitecture08,
    Galleryalt: "Low-budget interiors in Andheri",
  },
  {
    id: 82,
    Category: "Commercial Architecture",
    CImage: CommercialArchitecture09,
    Galleryalt: "High-tech offices in Andheri",
  },
  {
    id: 83,
    Category: "Commercial Architecture",
    CImage: CommercialArchitecture10,
    Galleryalt: "Real estate design trends in Andheri",
  },
  {
    id: 84,
    Category: "Commercial Architecture",
    CImage: CommercialArchitecture11,
    Galleryalt: "Full-service architecture in Andheri",
  },
];

export default GalleryData;
