import InteriorDesign from "../Images/Pages/Service/06.jpg";
import Architecture from "../Images/Pages/Service/Copy of njhhkm.jpg";
import FurnitureFrenzy from "../Images/Pages/Service/Copy of 09.jpg";

// residential interior 
import residentialinteriorvertical from "../Images/Pages/ServiceDetails/Interior Design/Residential Interior/Residential Interior1.webp"
import residentialinterior01 from "../Images/Pages/ServiceDetails/Interior Design/Residential Interior/Residential Interior2.webp";
import residentialinterior02 from "../Images/Pages/ServiceDetails/Interior Design/Residential Interior/Residential Interior3.webp";

// commercial interior
import commercialinteriorvertical from "../Images/Pages/ServiceDetails/Interior Design/Commercial Interior/commercialinteriordesign09.webp"
import commercialinterior01 from "../Images/Pages/ServiceDetails/Interior Design/Commercial Interior/commercialinteriordesign08.webp";
import commercialinterior02 from "../Images/Pages/ServiceDetails/Interior Design/Commercial Interior/commercialinteriordesign22.webp";
// Architecture
import residentialarchitecturevertical from "../Images/Pages/ServiceDetails/Architecture/Residential Architecture/residentialarchitecture58.webp";
import residentialarchitecture01 from "../Images/Pages/ServiceDetails/Architecture/Residential Architecture/Copy of njhhkm.webp";
import residentialarchitecture02 from "../Images/Pages/ServiceDetails/Architecture/Residential Architecture/manjeri 4.webp";

// commercial architecture
import commercialarchitecturevertical from "../Images/Pages/ServiceDetails/Architecture/Commercial Architecture/commercialarchitecture12.webp";
import commercialarchitecture01 from "../Images/Pages/ServiceDetails/Architecture/Commercial Architecture/commercialarchitecture6.webp";
import commercialarchitecture02 from "../Images/Pages/ServiceDetails/Architecture/Commercial Architecture/commercialarchitecture7.webp";
// furniture frenzy
import furniturefrenzyvertical from "../Images/Pages/ServiceDetails/furniture frenzy/furniturefrenzy07.webp";
import furniturefrenzy01 from "../Images/Pages/ServiceDetails/furniture frenzy/furniturefrenzy01.webp";
import furniturefrenzy02 from "../Images/Pages/ServiceDetails/furniture frenzy/furniturefrenzy02.webp";


const contentData = [
    {
        slugdata: "/best-interior-designer-andheri",
        servicename: "Interior Design",
        ServiceImage: InteriorDesign,
        alt:"Interior Design In Andheri",
        servicealt: "Interior decorating Andheri",
        Subservice: [
            {
                id: 1,
                servicename: "residential interior",
                Serviceh1tag:"Best Interior Designer in Andheri",
                Servicetitle:"Best Interior Designer in Andheri | HIVE",
                ServiceDes:"Find the best interior designer in Andheri to elevate your space with innovative designs and impeccable style. Contact us to bring your vision to life!",
                ServiceCanonical:"https://thehivemakers.com/best-interior-designer-andheri",
                header: "Elevate Your Home with Expert Residential Interior Design in Andheri",
                content: "In Andheri, Mumbai, transforming your living space into a haven of style and comfort is effortless with the expertise of the best interior designer in the area. Residential interior design goes beyond mere decoration; it's about creating functional, inviting, and aesthetically pleasing environments that reflect your personality and lifestyle. Discover why partnering with the best interior designer in Andheri is essential for realizing your dream home.",
                img: "https://images.pexels.com/photos/462118/pexels-photo-462118.jpeg?cs=srgb&dl=bloom-blooming-blossom-462118.jpg&fm=jpg",
                desc1: "Why Choose the Best Interior Designer in Andheri?",
                para1: "The best interior designer in Andheri brings creativity, expertise, and attention to detail to every project, ensuring that your home reflects your unique taste and preferences. Whether you're looking to revamp a single room or undertake a complete home renovation, collaborating with a skilled interior designer in Andheri ensures exceptional results that exceed your expectations.",
                detail1: "Visit Best Interior Designer in Andheri to schedule a consultation and embark on your journey to a beautifully designed home. With the skill and creativity of the best interior designer in Andheri, your vision for the perfect living space becomes a stunning reality.",
                slugdata: "/best-interior-designer-andheri",
                Points: [
                    {
                        pointshead01: "Space Planning:",
                        pointshead01para01: "Effective space planning is essential for maximizing functionality and flow within your home. The best interior designer in Andheri carefully evaluates your space, considering factors such as traffic patterns, furniture placement, and architectural features to optimize every square inch.",
                    },
                    {
                        pointshead01: "Color Palette and Material Selection:",
                        pointshead01para01: "Choosing the right color palette and materials sets the tone for your home's interior design. From soothing neutrals to bold statement hues, the best interior designer in Andheri helps you select colors and materials that complement your style and enhance your living space's ambiance.",
                    },
                    {
                        pointshead01: "Furniture and Accessories:",
                        pointshead01para01: "Furniture selection and arrangement play a significant role in defining the look and feel of your home. The best interior designer in Andheri curates furniture pieces and accessories that strike the perfect balance of comfort, functionality, and style, creating inviting and cohesive spaces throughout your home.",
                    },
                    {
                        pointshead01: "Lighting Design:",
                        pointshead01para01: "Thoughtfully designed lighting enhances the atmosphere and functionality of your home. The best interior designer in Andheri incorporates a mix of ambient, task, and accent lighting to illuminate your space effectively and create visual interest.",
                    },
                    {
                        pointshead01: "Transform Your Home with the Best Interior Designer in Andheri:",
                        pointshead01para01: "In Andheri, Mumbai, residents can turn their interior design dreams into reality with the expertise of the best interior designer in the area. With a keen eye for detail and a commitment to excellence, the best interior designer in Andheri elevates your home's aesthetic appeal and enhances your quality of life.",
                    },
                ],
                verticalImage: residentialinteriorvertical,
                Imagesdata: [
                    {
                        Images: residentialinterior01,
                        alt:"Residential interior design services in Andheri",
                    },
                    {
                        Images: residentialinterior02,
                        alt:"Luxury residential interiors in Andheri",
                    },
                ]
            },
            {
                id: 3,
                servicename: "commercial interior",
                Serviceh1tag:"Top Interior Designer in Andheri",
                Servicetitle:"Top Interior Designer in Andheri | HIVE",
                ServiceDes:"Elevate your space with the expertise of HIVE, the top interior designer in Andheri, crafting innovative and timeless designs.",
                ServiceCanonical:"https://thehivemakers.com/top-interior-designer-andheri",
                header: "Elevate Your Workspace with Expert Commercial Interior Design in Andheri",
                content: "In Andheri, Mumbai, businesses can create inspiring and functional work environments with the expertise of the top interior designer in the area. Commercial interior design plays a crucial role in shaping the atmosphere and functionality of workplaces, reflecting the brand's identity and enhancing productivity. Discover why partnering with the top interior designer in Andheri is essential for transforming your commercial space into a dynamic hub of creativity and success.",
                img: "https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?cs=srgb&dl=daylight-environment-forest-459225.jpg&fm=jpg",
                desc1: "Why Choose the Top Interior Designer in Andheri for Commercial Projects?",
                para1: "The top interior designer in Andheri brings creativity, innovation, and industry expertise to every commercial project, ensuring that your space reflects your brand values and meets your business objectives. From corporate offices to retail stores and hospitality venues, collaborating with a skilled interior designer in Andheri ensures exceptional results that elevate your business.",
                detail1: "Visit Top Interior Designer in Andheri to schedule a consultation and explore how commercial interior design can transform your workspace. With the skill and creativity of the top interior designer in Andheri, your commercial space becomes a dynamic and inspiring environment that sets you apart from the competition.",
                slugdata: "/top-interior-designer-andheri",
                verticalImage: commercialinteriorvertical,
                servicealt:"Top Interior Designer in Andheri",
                Points: [
                    {
                        pointshead01: "Branding and Identity:",
                        pointshead01para01: "Commercial interior design is an opportunity to reinforce your brand's identity and create a memorable experience for customers and employees alike. The top interior designer in Andheri works closely with you to develop a design concept that aligns with your brand values and resonates with your target audience.",
                    },
                    {
                        pointshead01: "Space Optimization:",
                        pointshead01para01: "Effective space planning is essential for optimizing functionality and maximizing efficiency in commercial settings. The top interior designer in Andheri carefully evaluates your space, considering factors such as workflow, traffic flow, and zoning regulations to create a layout that enhances productivity and enhances the user experience.",
                    },
                    {
                        pointshead01: "Aesthetics and Atmosphere:",
                        pointshead01para01: "The aesthetic appeal of your commercial space sets the tone for customer perceptions and employee morale. The top interior designer in Andheri employs a mix of colors, materials, and finishes to create visually appealing environments that inspire creativity, foster collaboration, and leave a lasting impression.",
                    },
                    {
                        pointshead01: "Functionality and Ergonomics:",
                        pointshead01para01: "Commercial interior design prioritizes functionality and ergonomics to ensure that spaces are comfortable, efficient, and conducive to productivity. The top interior designer in Andheri selects furniture, fixtures, and equipment that meet the specific needs of your business and enhance the overall user experience.",
                    },
                    {
                        pointshead01: "Transform Your Commercial Space with the Top Interior Designer in Andheri:",
                        pointshead01para01: "In Andheri, Mumbai, businesses can elevate their commercial spaces with the expertise of the top interior designer in the area. With a focus on innovation, functionality, and aesthetics, the top interior designer in Andheri delivers customized solutions that drive business success and enhance brand identity.",
                    },
                ],
                Imagesdata: [
                    {
                        Images: commercialinterior01,
                        alt:"Interior Designer in Andheri",
                    },
                    {
                        Images: commercialinterior02,
                        alt:"Interior Designer near me",
                    },
                ]
            },
        ]
    },
    {
        slugdata: "/top-architecture-andheri",
        servicename: "Architecture",
        ServiceImage: Architecture,
        servicealt: "Andheri home interior services",
        Subservice: [
            {
                id: 2,
                servicename: "residential architecture",
                Serviceh1tag:"Architecture in Andheri",
                Servicetitle:"Top Architecture in Andheri | HIVE",
                ServiceDes:"Explore the Top Architecture in Andheri with our curated list of designers. Discover innovative designs and skilled architects dedicated to crafting.",
                ServiceCanonical:"https://thehivemakers.com/top-architecture-in-andheri",
                header: "Crafting Your Dream Home: Exploring Residential Architecture in Andheri",
                content: "In Andheri, Mumbai, the pursuit of your dream home begins with the expertise of residential architecture professionals who understand the unique charm and lifestyle of the area. Residential architecture in Andheri encompasses a blend of innovation, functionality, and aesthetic appeal, resulting in homes that are both timeless and tailored to your needs. Discover why collaborating with experts in residential architecture in Andheri is the key to turning your vision into reality.",
                img: "https://images.pexels.com/photos/371589/pexels-photo-371589.jpeg?cs=srgb&dl=clouds-conifer-daylight-371589.jpg&fm=jpg",
                desc1: "Why Choose Residential Architecture in Andheri?",
                para1: "Residential architecture in Andheri is characterized by its attention to detail, creativity, and commitment to meeting the diverse needs of homeowners. Whether you're looking to build a contemporary villa, a traditional bungalow, or a modern apartment, collaborating with architects in Andheri ensures that your home reflects your style, preferences, and lifestyle.",
                detail1: "Contact Architecture in Andheri to schedule a consultation and explore how residential architecture professionals can bring your dream home to life. With their expertise and creativity, your vision for the perfect home becomes a reality in the vibrant landscape of Andheri.",
                slugdata: "/top-architecture-andheri",
                verticalImage: residentialarchitecturevertical,
                Points: [
                    {
                        pointshead01: "Customized Design Solutions:",
                        pointshead01para01: "Architects in Andheri offer customized design solutions tailored to your specific requirements and preferences. From initial concept development to final construction drawings, residential architecture professionals work closely with you to bring your vision to life while considering factors such as space utilization, natural lighting, and energy efficiency.",
                    },
                    {
                        pointshead01: "Integration with Surroundings:",
                        pointshead01para01: "Residential architecture in Andheri harmoniously integrates homes with their surroundings, whether it's the bustling urban landscape or the serene natural environment. Architects in Andheri carefully consider site characteristics, views, and local building regulations to create homes that blend seamlessly with their surroundings while maximizing functionality and comfort.",
                    },
                    {
                        pointshead01: "Focus on Sustainability:",
                        pointshead01para01: "Sustainable design principles are integral to residential architecture in Andheri, with architects prioritizing energy efficiency, eco-friendly materials, and green building practices. By incorporating sustainable features into your home, such as solar panels, rainwater harvesting systems, and passive ventilation, architects in Andheri help reduce environmental impact while enhancing your quality of life.",
                    },
                    {
                        pointshead01: "Attention to Detail:",
                        pointshead01para01: "Residential architecture in Andheri is characterized by its meticulous attention to detail, from the selection of materials to the execution of architectural elements. Architects in Andheri strive for perfection in every aspect of design, ensuring that every corner of your home reflects craftsmanship, sophistication, and elegance.",
                    },
                    {
                        pointshead01: "Begin Your Journey with Residential Architecture in Andheri:",
                        pointshead01para01: "In Andheri, Mumbai, homeowners can embark on their journey to a dream home with the expertise of architects who understand the unique character of the area. With a passion for design excellence and a commitment to client satisfaction, residential architecture in Andheri transforms your aspirations into architectural masterpieces that stand the test of time.",
                    },
                ],
                Imagesdata: [
                    {
                        Images: residentialarchitecture01,
                        alt:"Top Architecture in Andheri",
                    },
                    {
                        Images: residentialarchitecture02,
                        alt:"Best Architecture in Andheri",
                    },
                ]
            },
            {
                id: 4,
                servicename: "commercial architecture",
                Serviceh1tag:"Top commercial architecture designer in andheri",
                Servicetitle:"Top commercial architecture designer in andheri | HIVE",
                ServiceDes:"HIVE Interior is renowned as the Top commercial architecture designer in Andheri, offering innovative designs and unparalleled expertise.",
                ServiceCanonical:"https://thehivemakers.com/top-commercial-architecture-designer-andheri",
                header: "Elevate Your Business Space with Top Commercial Architecture Designer in Andheri",
                content: "In the bustling commercial hub of Andheri, Mumbai, businesses can make a statement with exceptional commercial architecture design crafted by the top commercial architecture designer in the area. Commercial architecture design plays a pivotal role in shaping the identity and functionality of business spaces, creating environments that inspire productivity, innovation, and success. Discover why partnering with the top commercial architecture designer in Andheri is essential for transforming your business space into a dynamic and inviting destination.",
                img: "https://th.bing.com/th/id/R.ae87891b3dde7b28712b75d14342f1a5?rik=2ZT%2baXLkZYcxWg&riu=http%3a%2f%2fthewowstyle.com%2fwp-content%2fuploads%2f2015%2f01%2fnature-wallpaper-27.jpg&ehk=jIVFSOxLN%2fQKs4hEfZHNWAeXoeXkeEXooP%2fTy9Vwkek%3d&risl=&pid=ImgRaw&r=0",
                desc1: "Why Choose the Top Commercial Architecture Designer in Andheri?",
                para1: "The top commercial architecture designer in Andheri brings expertise, creativity, and innovation to every project, ensuring that your business space reflects your brand identity and meets your functional requirements. Whether you're planning a corporate office, retail store, restaurant, or hospitality venue, collaborating with a skilled commercial architecture designer in Andheri guarantees exceptional results that elevate your business.",
                detail1: "Contact Top commercial architecture designer in Andheri to schedule a consultation and explore how commercial architecture design can transform your business space into a thriving and inspiring destination. With their expertise and vision, your business space becomes a reflection of your brand's excellence and ambition in the vibrant landscape of Andheri.",
                slugdata: "/top-commercial-architecture-designer-andheri",
                verticalImage: commercialarchitecturevertical,
                servicealt:"Top commercial architecture designer in andheri",
                Points: [
                    {
                        pointshead01: "Branding and Identity:",
                        pointshead01para01: "Commercial architecture design is an opportunity to express your brand's identity and create a memorable experience for customers and employees alike. The top commercial architecture designer in Andheri works closely with you to develop a design concept that aligns with your brand values, vision, and target audience.",
                    },
                    {
                        pointshead01: "Space Optimization:",
                        pointshead01para01: "Effective space planning is essential for maximizing functionality and efficiency in commercial settings. The top commercial architecture designer in Andheri carefully evaluates your space, considering factors such as traffic flow, zoning regulations, and customer experience to create layouts that enhance productivity and customer satisfaction.",
                    },
                    {
                        pointshead01: "Aesthetics and Atmosphere:",
                        pointshead01para01: "The aesthetic appeal of your business space sets the tone for customer perceptions and employee morale. The top commercial architecture designer in Andheri employs a mix of architectural elements, materials, and finishes to create visually striking environments that captivate and engage visitors while reflecting your brand's personality and values.",
                    },
                    {
                        pointshead01: "Functionality and Adaptability:",
                        pointshead01para01: "Commercial architecture design prioritizes functionality and adaptability to ensure that spaces are flexible and able to accommodate evolving business needs. The top commercial architecture designer in Andheri designs spaces that are not only visually appealing but also practical, efficient, and capable of supporting your business objectives.",
                    },
                    {
                        pointshead01: "Transform Your Business Space with the Top Commercial Architecture Designer in Andheri:",
                        pointshead01para01: "In Andheri, Mumbai, businesses can elevate their commercial spaces with the expertise of the top commercial architecture designer in the area. With a focus on innovation, creativity, and client satisfaction, the top commercial architecture designer in Andheri delivers customized solutions that enhance your brand's image and drive business success.",
                    },
                ],
                Imagesdata: [
                    {
                        Images: commercialarchitecture01,
                        alt:"Top commercial architecture designer in andheri",
                    },
                    {
                        Images: commercialarchitecture02,
                        alt:"best commercial architecture designer in andheri",
                    },
                ]
            },
        ],
    },
    {
        slugdata: "/interior-designing-furnishing-andheri",
        servicename: "Furniture Frenzy",
        ServiceImage: FurnitureFrenzy,
        servicealt: "Andheri interior renovation services",
        Subservice: [
            {
                id: 5,
                servicename: "furniture frenzy",
                Serviceh1tag:"Interior Designing With Furnishing Services In Andheri",
                Servicetitle:"Interior Designing With Furnishing Work Andheri | HIVE",
                ServiceDes:"Experience luxury with our bespoke Interior Designing and Furnishing Services in Andheri. Elevate your living space to new heights of sophistication & comfort. ",
                ServiceCanonical:"https://thehivemakers.com/interior-designing-furnishing-andheri",
                header: "Experience Furniture Frenzy Design: Interior Designing With Furnishing Services in Andheri",
                content: "In the bustling neighborhood of Andheri, Mumbai, elevate your interior spaces with the unique blend of style and functionality offered by furniture frenzy design. Combining innovative design concepts with top-notch furnishing services, interior designing with furnishing services in Andheri creates spaces that are both visually stunning and supremely comfortable. Explore why embracing furniture frenzy design is the perfect choice for transforming your living or workspace into a haven of elegance and sophistication.",
                img: "https://cdn.wallpapersafari.com/58/77/xB37fF.jpg",
                desc1: "Embrace Interior Designing With Furnishing Services in Andheri:",
                para1: "Interior designing with furnishing services in Andheri takes your design aspirations to new heights, offering comprehensive solutions that encompass every aspect of interior décor. From conceptualization to execution, our team of skilled designers and craftsmen work tirelessly to bring your vision to life, ensuring that every detail is meticulously curated to perfection.",
                slugdata: "/interior-designing-furnishing-andheri",
                verticalImage: furniturefrenzyvertical,
                Points: [
                    {
                        pointshead01: "Tailored Design Solutions:",
                        pointshead01para01: "At furniture frenzy design, we believe that quality furnishing services are the cornerstone of exceptional interior design. From sourcing premium materials to coordinating installations, our team ensures that every furnishing element meets the highest standards of craftsmanship and durability, guaranteeing lasting beauty and functionality.",
                    },
                    {
                        pointshead01: "Attention to Detail:",
                        pointshead01para01: "We understand that it's the little details that make a big difference in interior design. From selecting the perfect furniture pieces to choosing coordinating fabrics and accessories, our team pays meticulous attention to every aspect of the design process, ensuring a cohesive and harmonious aesthetic that reflects your style and personality.",
                    },
                    {
                        pointshead01: "Effortless Transformation:",
                        pointshead01para01: "With interior designing with furnishing services in Andheri, transforming your space is a seamless and stress-free experience. Our team handles every aspect of the design and furnishing process, from initial consultations to final installations, allowing you to sit back, relax, and watch your vision come to life before your eyes.",
                    },
                    {
                        pointshead01: "Experience the Magic of Furniture Frenzy Design:",
                        pointshead01para01: "Ready to elevate your interior spaces with the unparalleled beauty and comfort of furniture frenzy design? Contact interior designing with furnishing services in Andheri today to schedule a consultation and discover how our expertise can transform your space into a masterpiece of design and functionality. With our commitment to excellence and attention to detail, your dream interior awaits.",
                    },
                ],
                Imagesdata: [
                    {
                        Images: furniturefrenzy01,
                        alt:"Interior Designing With Furnishing Services In Andheri",
                    },
                    {
                        Images: furniturefrenzy02,
                        alt:"Interior Designing With Furnishing Services",
                    },
                ]
            },
        ]
    },
];

export default contentData;