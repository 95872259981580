import React from 'react';
import "../Architecture/ArchitectureSec.css";
import image01 from "../../Images/Pages/Home/HomeAbout01.webp";
import image02 from "../../Images/Pages/Home/HomeAbout02.webp";
import { GoPlus } from "react-icons/go";
const ArchitectureSec = () => {
    return (
        <>
            <section className='ArchitectureSec-con'>
                <div className='container'>
                    <div className='Home-Architecture-Sec-main-con'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='Home-Architecture-Sec-Image-main-con'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className='Home-Architecture-Se-Image1-con'>
                                                <div className='Home-Architecture-Sec-Image1'>
                                                    <img src={image01} alt='interior in andheri' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='Home-Architecture-Se-Image2-con'>
                                                <div className='Home-Architecture-Sec-Image2'>
                                                    <img src={image02} alt='andheri interior decorators' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-5'>
                                <div className='Home-Architecture-Sec-Details-con-main'>
                                    <div className='Home-Architecture-Sec-heading'>
                                        <h2>ARCHITECTURE ET CRÉATIVITÉ_</h2>
                                    </div>
                                    <div className='Home-Architecture-Sec-Details-con'>
                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <div className='Home-Architecture-Sec-Info-con'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            <div className='Home-Architecture-Sec-Info-icon'>
                                                                <GoPlus />
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <div className='Home-Section-Architecture-Details'>
                                                                <h3>Functional Spaces</h3>
                                                                <p>Designing spaces that optimize functionality while enhancing aesthetic appeal and comfort.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='Home-Architecture-Sec-Info-con'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            <div className='Home-Architecture-Sec-Info-icon'>
                                                                <GoPlus />
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <div className='Home-Section-Architecture-Details'>
                                                                <h3>Interior Design</h3>
                                                                <p>Elevating interiors with sophisticated design, balancing style and functionality seamlessly.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='Home-Architecture-Sec-Info-con'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            <div className='Home-Architecture-Sec-Info-icon'>
                                                                <GoPlus />
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <div className='Home-Section-Architecture-Details'>
                                                                <h3>Structural Design</h3>
                                                                <p>Creating robust structures with innovative designs, blending form and function harmoniously</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='Home-Architecture-Sec-Info-con'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            <div className='Home-Architecture-Sec-Info-icon'>
                                                                <GoPlus />
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <div className='Home-Section-Architecture-Details'>
                                                                <h3>Award-Winning Designs</h3>
                                                                <p>Recognized for outstanding designs that redefine architectural excellence and innovation.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='Home-Architecture-Sec-Info-con'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            <div className='Home-Architecture-Sec-Info-icon'>
                                                                <GoPlus />
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <div className='Home-Section-Architecture-Details'>
                                                                <h3>Influential And Impactful</h3>
                                                                <p>Inspiring designs shaping trends, leaving a lasting impact on architecture.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='Home-Architecture-Sec-Info-con'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            <div className='Home-Architecture-Sec-Info-icon'>
                                                                <GoPlus />
                                                            </div>
                                                        </div>
                                                        <div className='col-10'>
                                                            <div className='Home-Section-Architecture-Details'>
                                                                <h3>Concept Drawings</h3>
                                                                <p>Illustrating visionary concepts with precise drawings, laying the foundation for execution.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ArchitectureSec