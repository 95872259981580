import React from 'react';
import "../HomeWelcome/Welcome.css";
import buildings from "../../Images/icons/buildings.png";
import plan from '../../Images/icons/plan.png';
import medal from "../../Images/icons/medal.png";

const Welcome = () => {
    return (
        <>
            <section className="welcome-area">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2 className='Head-Style'>Welcome to Hive</h2>
                    </div>
                    <div className="row">
                        {/*Start single item*/}
                        <div className="col-md-4">
                            <div className="single-item text-center">
                                <div className="icon-holder">
                                    <div className="flaticon-medical-6">
                                        <img src={buildings} alt='Best Interior Design' />
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Building the future cities</h3>
                                    <p>
                                        Crafting innovative solutions in interior design in Andheri to transform cities into thriving urban environments for tomorrow.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4">
                            <div className="single-item text-center">
                                <div className="icon-holder">
                                    <div className="flaticon-medical-6">
                                        <img src={plan} alt='Unique and influential interior design' />
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Unique and influential design</h3>
                                    <p>
                                        Distinctive design in interior in Andheri, shaping trends with creativity and influence for unparalleled impact and recognition.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                        {/*Start single item*/}
                        <div className="col-md-4">
                            <div className="single-item text-center">
                                <div className="icon-holder">
                                    <div className="flaticon-medical-6">
                                        <img src={medal} alt='Interior Design Services In Andheri' />
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3>Award-winning Architecture</h3>
                                    <p>
                                    Eminent architecture and interior in Andheri honored for groundbreaking designs, setting new standards in architectural excellence and innovation.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/*End single item*/}
                    </div>
                </div>
            </section>

        </>
    )
}

export default Welcome