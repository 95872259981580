import React, { useLayoutEffect, useState } from 'react';
import "../Css/GalleryPage.css";
import GalleryData from '../Component/Gallery/GalleryData';
import Tabs from '../Component/Gallery/Tabs';
import Items from '../Component/Gallery/Items';
import Footer from "../Component/Footer/FooterPage";
import { MetaTags } from 'react-meta-tags';

const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }

    return (
        <>
            <MetaTags>
                <title> Interior Decorator in Andheri | HIVE</title>
                <meta title=" Interior Decorator in Andheri | HIVE" />
                <meta name="description" content="Seeking an expert Interior Decorator in Andheri? Beautify your space with our professional expertise. Contact us now for a consultation!" />
                <meta name="keywords" content="Interior in Andheri,Custom Interior Designers in Andheri,Interior Design Services in Andheri,Best Interior Designer in Andheri,Top Architecture in Andheri,Interior Designing With Furnishing Services Andheri,Interior Decorator in Andheri,Interior Designer near Andheri,Best Interior Designer in Andheri,Top Interior Designer in Andheri,Architecture in Andheri,Top commercial architecture designer in andheri,Interior Designing With Furnishing Services Andheri" />
                <link rel="canonical" href="https://thehivemakers.com/interior-decorator-andheri" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content=" Interior Decorator in Andheri | HIVE" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://thehivemakers.com/interior-decorator-andheri" />
                <meta
                    property="og:description"
                    content="Seeking an expert Interior Decorator in Andheri? Beautify your space with our professional expertise. Contact us now for a consultation!"
                />
                <meta
                    property="og:image"
                    content="https://thehivemakers.com/static/media/Logo2.d18c2a41e337393532b6.png"
                />
            </MetaTags>

            <section className="Gallery-Section-1">
                <div className="container">
                    <div className="Gallery-Section-con">
                        <div className="Gallery-Section-Text">
                            <h1>Interior Decorator in Andheri</h1>
                            <div className="Gallery-Section-NavPage">
                                <span>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                                    </svg>
                                    Home
                                </span>
                                <h3>
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth={0}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                                    </svg>
                                </h3>
                                <h2>Gallery</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>
                    <h2 className='d-none'>Welcome to the captivating world of Hive Interior Designer, where innovation meets elegance to redefine spaces. As premier Interior Decorators in Andheri, our gallery page showcases a curated collection of our finest works, each a testament to our commitment to exceptional design. Explore stunning residential and commercial projects that reflect our passion for blending creativity with functionality. From chic urban apartments to luxurious corporate offices, our portfolio captures the essence of modern living infused with timeless style. Dive into our gallery and discover how Hive Interior Designer transforms visions into breathtaking realities, one space at a time.</h2>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='Gallery-Section-Tabs-con'>
                                    <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                </div>
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>/

            <Footer />

        </>
    )
}

export default Gallery