import React, { useLayoutEffect } from "react";
import "../Css/About.css";
import homeabout01 from "../Images/Pages/Gallery/residential interior design/residentialinterior38.webp";
import HomeTeam from '../Component/Team/HomeTeam';
import FooterPage from "../Component/Footer/FooterPage";
import Mission from "../Component/OurMission/Mission";
import { MetaTags } from "react-meta-tags";

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>
      <MetaTags>
        <title>Custom Interior Designers in Andheri | HIVE</title>
        <meta title="Custom Interior Designers in Andheri | HIVE" />
        <meta name="description" content="Explore top-rated Custom Interior Designers in Andheri, Mumbai. Transform your space with bespoke design solutions tailored to your preferences and lifestyle." />
        <meta name="keywords" content="Interior in Andheri,Custom Interior Designers in Andheri,Interior Design Services in Andheri,Best Interior Designer in Andheri,Top Architecture in Andheri,Interior Designing With Furnishing Services Andheri,Interior Decorator in Andheri,Interior Designer near Andheri,Best Interior Designer in Andheri,Top Interior Designer in Andheri,Architecture in Andheri,Top commercial architecture designer in andheri,Interior Designing With Furnishing Services Andheri" />
        <link rel="canonical" href="https://thehivemakers.com/custom-interior-designers-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Custom Interior Designers in Andheri | HIVE" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thehivemakers.com/custom-interior-designers-andheri" />
        <meta
          property="og:description"
          content="Explore top-rated Custom Interior Designers in Andheri, Mumbai. Transform your space with bespoke design solutions tailored to your preferences and lifestyle."
        />
        <meta
          property="og:image"
          content="https://thehivemakers.com/static/media/Logo2.d18c2a41e337393532b6.png"
        />
      </MetaTags>

      <section className="ProductList-Section-1">
        <div className="container">
          <div className="ProductList-Section-con">
            <div className="ProductList-Section-Text">
              <h1>Custom Interior Designers in Andheri</h1>
              <div className="ProductList-Section-NavPage">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" />
                  </svg>
                  Home
                </span>
                <h3>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                  </svg>
                </h3>
                <h2>About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-about-section-con">
        <div className="container">
          <div className="about-section-image-details">
            <div className="row">
              <div className="col-md-6 p-0">
                <div class="about-us-one-left">
                  <img src={homeabout01} alt="Custom Interior Designers in Andheri" />
                </div>
              </div>
              <div className="col-md-6 p-0">
                <div className="about-section-page-about-details-con">
                  <div className="about-section-page-about-details">
                    <div className="about-section-page-about-info">
                      <h3>Welcome to Hive</h3>
                      <p>
                        Welcome to Hive Interior and Architecture, where creativity meets functionality to transform spaces into captivating realms of design excellence. As custom interior designers in Andheri, at Hive, we believe that every structure has a story waiting to be told, and we are passionate about crafting narratives through innovative design solutions. With a keen eye for detail and a commitment to quality, our team of experienced architects and designers work tirelessly to bring your vision to life, creating spaces that inspire, engage, and evoke a sense of timeless elegance. From concept to completion, we collaborate closely with our clients to ensure that every project reflects their unique personality and exceeds their expectations. Whether it's residential, commercial, or hospitality design, Hive Interior and Architecture is dedicated to delivering exceptional results that elevate the human experience and leave a lasting impression.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Mission />
      <HomeTeam />
      <FooterPage />
    </>
  );
};

export default About;
