import React, { useLayoutEffect, useState } from "react";
import "../Css/Home.css";
import Footer from "../Component/Footer/FooterPage";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Image } from 'antd';
import HomeAbout from "../Component/About/HomeAbout";
import Welcome from "../Component/HomeWelcome/Welcome";
import ArchitectureSec from "../Component/Architecture/ArchitectureSec";
import HomeProcess from "../Component/Process/HomeProcess";
import Testimonial from "../Component/HomeTestimonial/Testimonial";
import { MetaTags } from "react-meta-tags";
import GalleryData from "../Component/Gallery/GalleryData";
import Tabs from "../Component/Gallery/Tabs";
import Zoom from 'react-reveal/Zoom';
import { Link } from "react-router-dom";

const Home = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const [data, setData] = useState(GalleryData);

  // Store Category in CategoryData
  const categoryData = GalleryData.map((value) => {
    return value.Category;
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];

  // for all categoryData set
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData);
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    });

    setData(filteredData);
  };

  const slicedArray = data.slice(0, 12);

  return (
    <>
      <MetaTags>
        <title>Interior in Andheri | HIVE</title>
        <meta title="Interior in Andheri | HIVE" />
        <meta name="description" content="Find top-notch Interior Design services in Andheri. Elevate your living or working space with our expert designers. Contact us now for a consultation!" />
        <meta name="keywords" content="Interior in Andheri,Custom Interior Designers in Andheri,Interior Design Services in Andheri,Best Interior Designer in Andheri,Top Architecture in Andheri,Interior Designing With Furnishing Services Andheri,Interior Decorator in Andheri,Interior Designer near Andheri,Best Interior Designer in Andheri,Top Interior Designer in Andheri,Architecture in Andheri,Top commercial architecture designer in andheri,Interior Designing With Furnishing Services Andheri" />
        <link rel="canonical" href="https://thehivemakers.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Interior in Andheri | HIVE" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thehivemakers.com/" />
        <meta
          property="og:description"
          content="Find top-notch Interior Design services in Andheri. Elevate your living or working space with our expert designers. Contact us now for a consultation!"
        />
        <meta
          property="og:image"
          content="https://thehivemakers.com/static/media/Logo2.d18c2a41e337393532b6.png"
        />
      </MetaTags>
      <section className="Home-section-Banner-con">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="Home-Banner-Image-con">
              <div className="Home-Banner-Text-Con">
                <div className="Home-Banner-Text-details">
                  {/* <h2>Welcome To Hive Construction And Builders</h2> */}
                  <h1>
                    Interior in Andheri
                  </h1>
                  <p>
                    Elevate your living spaces with Hive, your premier destination for interior design in Andheri. From concept to creation, we make every corner a masterpiece of style and comfort. Transform your home with Hive, where every detail reflects your unique taste and lifestyle.                  </p>
                  <div className="Home-Banner-btn-con">
                    <a href="tel:+919653460781">
                      <button className="custom-btn btn-11">
                        Book Appointment<div className="dot"></div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <Welcome />
      <ArchitectureSec />
      <HomeAbout />
      <div className="Gallery-Section-2">
        <div className="Gallery-Card">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="Gallery-Section-Tabs-con">
                  <div className="row col-md-12">
                    <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                  </div>
                </div>
                <div className='row'>
                  {
                    slicedArray.map((value) => {
                      const { id, CImage, Galleryalt } = value

                      return (
                        <>
                          <Zoom>
                            <div className="col-lg-4 col-md-6 my-3" key={id}>
                              <div className="card bg-light text-center">
                                <Image src={CImage} alt={Galleryalt} />
                                {/* <div className='Card-text'>
                                                <h2>{CategoryName}</h2>
                                            </div> */}
                              </div>
                            </div>
                          </Zoom>
                        </>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="Home-Gallery-btn-con">
              <Link to="/interior-decorator-andheri">
                <div className="Home-Gallery-btn">
                  View All
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <HomeProcess />
      <Testimonial />
      <Footer />
    </>
  );
};

export default Home;
